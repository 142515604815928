<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Could Poor Storage be Contributing to the High Diabetes Cases in Africa
        Urban Areas?
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634722663/health-care-doctor-help-concept_qk8hfw.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Could Poor Storage be Contributing to the High Diabetes Cases in
            Africa Urban Areas?
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Oct 20, 2021</p>
            </span>
          </div>
          <p>
            Carriers of Life had an exclusive interview with Dr Ogunwale, a
            consultant whose services has been benefited from by many top-tier
            hospitals in Nigeria. In this interview, Dr Ogunwale properly
            defined and described diabetes, its types, causes and prevention. He
            also went ahead to talk extensively on the problem of lack of proper
            storage in the prevention and treatment of diabetes. Here is an
            overview of what was discussed in the sure-fire interview.
          </p>
          <div class="block">
            <p class="font-weight-bold text-h5">
              What is diabetes?
            </p>
            <p class="font-italics">
              "Diabetes is a metabolic disease that occurs when the body has
              problems handling food like carbohydrates, fat and protein. This
              is because there is a problem in the production of insulin." Dr
              Ogunwale explained
            </p>
            <p>
              Diabetes is a health condition marked by unusually high blood
              sugar levels. The most common type of diabetes is diabetes
              mellitus which is mostly referred to as Diabetes. Having diabetes
              is a result of the pancreas failing to generate any or enough
              insulin, or insulin production failing to be recognized and
              utilized properly by the body's cells. Blood sugar levels are
              controlled by a hormone called insulin.
            </p>
            <p>
              Diabetes-induced high blood sugar can harm the nerves, eyes,
              kidneys, and other organs if left untreated. It could also lead to
              recurrent infections. This is because high glucose levels make it
              more difficult for the body to repair itself when injured.
            </p>
            <p>
              Dr Ogunwale also mentioned that "Over 60% of people in Africa with
              diabetes do not even know they have it even when the symptoms are
              obvious. This is why we advise that you check your blood glucose
              regularly and be alert to changes in your body like more frequent
              urination."
            </p>
          </div>

          <figure class="pt-8 pb-4">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634722947/world-diabetes-day-medical-equipment-wooden-floor_v90yta.jpg"
              alt=""
              width="100%"
              class=""
            />
          </figure>
          <div>
            <p class="font-weight-bold text-h5">
              Diabetes Symptoms
            </p>
            <p>
              Some of the general symptoms of diabetes include increased hunger,
              increased thirst, weight loss, frequent urination, blurry vision,
              extreme fatigue and sores that don’t heal. There are however other
              symptoms that are particular to certain types of diabetes.
              Diabetes symptoms might be subtle at first, making them difficult
              to detect.
            </p>
          </div>
          <figure class="pt-8 pb-4">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634721605/29002_wqzige.jpg"
              alt=""
              width="100%"
              class=""
            />
          </figure>
          <div>
            <p class="font-weight-bold text-h5">
              Causes of diabetes
            </p>
            <p>
              The causes of Type 1 diabetes remains a mystery to medical
              professionals. All we know is that the immune system mistakenly
              attacks and destroys insulin-producing beta cells in the pancreas
              for whatever reason. In some people, genes may play a role. It's
              also possible that a virus triggers an immunological response.
            </p>
            <p>
              Diabetes type 2, the most common type of diabetes is caused by a
              combo of genetics and lifestyle factors. Speaking on the prominent
              causes of diabetes mellitus in Nigeria, Dr Ogunwale said,
              <span class="grey-text font-italics">
                "Diabetes is caused by genetics, obesity or a person's
                lifestyle. If a parent has diabetes, the children have more than
                a 30% risk of developing diabetes. If both parents have diabetes
                the risk increases by more than 50%. Obesity also is one of the
                major factors that is responsible for the increased prevalence
                of diabetes in the Nigerian population. People who are obese
                tend to get diabetes because of a habit of eating foods high in
                sugar and a lack of exercise".
              </span>
              Type 2 diabetes mostly affects people aged 45 or older, and people
              have high blood pressure, high cholesterol, or high triglycerides.
            </p>
            <p>
              Hormonal changes occur throughout pregnancy and can lead to
              gestational diabetes. The placenta secretes hormones that lessen
              the sensitivity of a pregnant woman's cells to the effects of
              insulin. When women are pregnant, this can lead to elevated blood
              sugar. Pregnant women who are overweight or acquire too much
              weight during pregnancy are more likely to develop gestational
              diabetes. It affects women older than 25 years.
            </p>
          </div>
          <div>
            <p class="font-weight-bold text-h5">
              The problem of storage in living a diabetes-free life!
            </p>
            <p>
              Diabetes is responsible for a lot of deaths and lost dreams in the
              world today. The best way to keep diabetes away from you is by
              adopting a healthy lifestyle and visiting a doctor when you see
              signs or symptoms suggestive of diabetes. But even in the
              prevention of diabetes to ensure healthy living, another problem
              arises - the problem of storage.
            </p>
            <p>
              Dr Ogunwale also discussed how cold-chain could be playing a role
              in mitigating the risk of diabetes, he highlighted that one of the
              causes of diabetes is eating food high in refined sugar ( a
              typical example of these are processed food like carbonated drinks
              and candy). He advised that people eat healthy meals, mostly
              organic, to reduce high sugar intake.
            </p>
          </div>

          <figure class="pt-8 pb-4">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634722950/world-diabetes-day-sugar-wooden-bowl-stethoscope-dark-background_t0cpld.jpg"
              alt=""
              width="100%"
              class=""
            />
          </figure>
          <p>
            However, for most Africans especially the lower class living in the
            urban regions of the continent, avoiding diabetes doesn’t come
            cheap, for example, in Lagos Nigeria, organic and healthy foods are
            not as affordable as junk food that could easily cause diabetes, and
            this could be traced to the inadequate cold-chain infrastructure in
            the country.
          </p>
          <p>
            Most of the Agro Hubs are away from the urban centres, as a result
            of the inadequate cold-chain storage and logistic infrastructure, a
            lot of the agro produce are lost to post-harvest loss which
            ultimately drives up the cost for residents and businesses within
            the urban area.
          </p>
          <p>
            Instead of consuming carbonated drinks, regular cakes, bread and
            other foods containing highly refined sugar, it is more advisable to
            take fruits, fruit juices and organic diets but with relatively high
            prices, for most residents, the healthy choice seems more like a
            luxury.
          </p>
          <figure class="pt-4 pb-1">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634722757/mixed-fruits-with-apple-banana-orange-other_nzupfy.jpg"
              alt=""
              width="100%"
              class=""
            />
          </figure>
          <p>
            Storing fresh food could pose to be quite a tough business because
            of the lack of proper cold storage systems, especially when these
            foods are being moved from the point of harvest to retail stores and
            then the consumer.
          </p>
          <p class="font-italics">
            "People with diabetes should see an ophthalmologist to have a
            comprehensive check of their eye at least once a year. They need to
            have their foot examined at least once or twice a year to check for
            any signs of the development of foot ulcers which could lead to
            amputation. Diabetes is the number one cause of non-accident or
            trauma regulated amputation of the leg."
          </p>
          <div class="block">
            <p class="text-h5">
              Prevention and management of diabetes
            </p>
            <p>
              Generally, lifestyle choices play a major role in preventing and
              managing diabetes, exercising and consuming natural glucose in the
              form of mango, banana, rice, cassava and other organic foods rich
              in carbohydrates reduces one's chances of having diabetes and
              taking high fibre organic meals helps a lot in managing the
              ailment. To ensure that organic foods remain nutritious and
              nourishing the issue of storage needs to be addressed, essentially
              a cold-chain facility is a must-have for every household.
            </p>
            <p>
              Addressing the topic of the prevention and management of diabetes,
              Dr Ogunwale stated, "When people are diagnosed with diabetes, they
              should have access to specialists which are diabetologists,
              endocrinologists or others. This serves to control the spread of
              the disease and to tackle complications that might have set in,"
            </p>
          </div>
          <figure class="pt-4 pb-1">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634721644/world-diabetes-day-docter-checking-patient-pulse-s_rdlqso.jpg"
              alt=""
              width="100%"
              class=""
            />
          </figure>
          <p>
            Those who have a history of diabetes in their families need to take
            caution to not develop full-blown diabetes. Here is what Dr Ogunwale
            advises, "People with a history of diabetes in their family should
            go to the hospital to check their glucose levels and test for
            prediabetes. If diagnosed with prediabetes, they could eat
            recommendations early on possible lifestyle modification and habits
            to adapt to live a healthy life and avoid full-blown diabetes."
          </p>
          <p>
            Cold chain facilities, equipment and appliances require constant
            electricity to be effective and unfortunately for a lot of regions
            on the African continent, electricity is either not accessible or
            expensive, cold chain technology that is inexpensive to acquire and
            manage would be the best fit for the region and they would easily
            have an impact on the health of the residents.
          </p>
          <p>
            In conclusion, Dr Ogunwale stated, "Diabetes is a killer. It affects
            the working population in Nigeria the most. Because of the
            percentage of deaths of this population, it could cause an economic
            crisis if not checked. I encourage everyone to modify their habits
            or lifestyle to live a more healthy life".
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import image4 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image5 from "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg";
import image6 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
import image7 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
// import image5 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
import image8 from "../../assets/blog new/pharmaceuticall.png";
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img: image7,
        content:
          "Digitalization in the African retail pharmaceutical sector (With Shelflife by Field intelligence and mPharma)",
        date: "Sept 19, 2021",
        link:
          "/blog/digitalisation-in-the-retail-pharmaceutical-sector-shelf-life-director-of-pharmacy-suleman-Sule-and-mPharmas-joyce-aderinlewo-talked-about-how-they-are-helping-thousands-of-community-pharmacies-use-data-and-technology-to-optimise-operations",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          "Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "Sept 12, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image8,
        content:
          "Gricd works with ISN to assure product integrity and avert waste risk.",
        date: "Sept 18, 2021",
        link:
          "/blog/gricd-works-with-isn-to-assure-product-integrity-and-avert-waste-risk",
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name:
                  "Could Poor Storage be Contributing to the High Diabetes Cases in Africa Urban Areas?",
                item:
                  "https://gricd.com/blog/could-poor-storage-be-contributing-to-the-high-diabetes-cases-in-africa-urban-areas",
              },
            ],
          },
        },
      ],
    };
  },
  created() {
    const readyHandler = () => {
      //   console.log(document.readyState);
      if (document.readyState == "complete") {
        this.loading = false;
        this.loaded = true;
        document.removeEventListener("readystatechange", readyHandler);
      }
    };

    document.addEventListener("readystatechange", readyHandler);

    readyHandler(); // in case the component has been instantiated lately after loading
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        // this.loading = false;
        // this.loaded = true;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
</style>
